import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './LoginPage.css';

function LoginPage({ setIsAuthenticated }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [requirePassword, setRequirePassword] = useState(false);
  const [storedUserData, setStoredUserData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const savedUserData = localStorage.getItem('userData');
    if (savedUserData) {
      const parsedData = JSON.parse(savedUserData);
      const lastLoginDate = new Date(parsedData.lastLogin);
      const now = new Date();
      const diffMinutes = Math.floor((now - lastLoginDate) / 1000 / 60);

      if (diffMinutes > 30 || !parsedData.email) {
        // Pokud session vypršela nebo není email
        setRequirePassword(true);
        localStorage.removeItem('userData'); // Odstranit neplatná data
      } else {
        setStoredUserData(parsedData);
      }
    }
  }, []);

  const handleLogin = async () => {
    try {
      const loginEmail = requirePassword && storedUserData ? storedUserData.email : email;
      const response = await axios.post('https://pvprng.a24vps.com/auth/login', {
        email: loginEmail,
        password, // Heslo musí být vyplněno ručně, pokud je vyžadováno
      });

      if (response.status === 200) {
        const userData = {
          email: loginEmail,
          profileImage: response.data.profileImage || null,
          username: response.data.username || 'Neznámý uživatel',
          lastLogin: new Date().toISOString(),
        };

        // Uložení pouze nezbytných dat (bez hesla)
        localStorage.setItem('userData', JSON.stringify(userData));
        localStorage.setItem('authToken', response.data.token);

        setIsAuthenticated(true);
        navigate('/game');
      }
    } catch (error) {
      console.error('Chyba při přihlášení:', error.response?.data || error.message);
      setErrorMessage(error.response?.data?.errors?.[0]?.msg || 'Přihlášení se nezdařilo.');
    }
  };

  const handleQuickLogin = async () => {
    try {
      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        setErrorMessage('Session vypršela. Přihlaste se prosím znovu.');
        return;
      }

      const response = await axios.get('https://pvprng.a24vps.com/verify-session', {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (response.status === 200) {
        setIsAuthenticated(true);
        navigate('/game');
      }
    } catch (error) {
      console.error('Chyba při ověřování session:', error.response?.data || error.message);
      setErrorMessage('Session vypršela. Přihlaste se prosím znovu.');
      handleLogout(); // Odstranění neplatné session
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('userData');
    localStorage.removeItem('authToken');
    setStoredUserData(null);
    setRequirePassword(false);
    setEmail('');
    setPassword('');
  };

  const goToRegister = () => {
    navigate('/register');
  };

  useEffect(() => {
    const verifySession = async () => {
      try {
        const token = localStorage.getItem('authToken');
        if (!token) {
          throw new Error('Neplatný token.');
        }
  
        const response = await axios.get('https://pvprng.a24vps.com/verify-session', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        if (response.status === 200) {
          setIsAuthenticated(true);
          navigate('/game');
        }
      } catch (error) {
        console.error('Chyba při ověřování session:', error.response?.data || error.message);
        setStoredUserData(null);
        localStorage.removeItem('authToken');
        setRequirePassword(true);
      }
    };
  
    verifySession();
  }, [navigate, setIsAuthenticated]);  

  useEffect(() => {
    const letters = document.querySelectorAll('.letter');
    const totalSpinTime = 300;
    const revealDelay = 50;

    setTimeout(() => {
      letters.forEach((letter, index) => {
        setTimeout(() => {
          letter.style.animation = 'none';
          letter.textContent = letter.getAttribute('data-final');
        }, index * revealDelay);
      });
    }, totalSpinTime);
  }, []);

  return (
    <div className="login-container">
      <div className="form-box">
        <h2 className="animated-title">
          <span className="letter" data-final="P">X</span>
          <span className="letter" data-final="V">Q</span>
          <span className="letter" data-final="P">L</span>
          <span>:</span>
          <span className="letter" data-final="R">A</span>
          <span className="letter" data-final="N">T</span>
          <span className="letter" data-final="G">K</span>
        </h2>
        {storedUserData ? (
          <div className="profile-container">
            <img
              src={storedUserData.profileImage || 'https://via.placeholder.com/100'}
              alt="Profil"
              className="profile-image"
              onClick={handleQuickLogin}
            />
            <p>{storedUserData.username}</p>
            {requirePassword ? (
              <>
                <input
                  type="password"
                  placeholder="Zadejte heslo"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button onClick={handleLogin}>Přihlásit se</button>
              </>
            ) : (
              <button className="link-button" onClick={handleQuickLogin}>
                Přihlásit se
              </button>
            )}
            <button className="link-button" onClick={handleLogout}>
              Změnit uživatele
            </button>
          </div>
        ) : (
          <>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            <input
              type="email"
              placeholder="E-mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="password"
              placeholder="Heslo"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button onClick={handleLogin}>Přihlásit se</button>
            <div className="register-prompt">
              <p>Nemáte účet?</p>
              <button className="link-button" onClick={goToRegister}>
                Registrovat se
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default LoginPage;
