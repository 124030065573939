import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import './Navbar.css';
import { Link, useNavigate } from 'react-router-dom';
import { CreditsContext } from '../context/CreditsContext';
import PaymentForm from './PaymentForm';

const Navbar = ({ handleLogout }) => {
  const { credits, setCredits } = useContext(CreditsContext);
  const [username, setUsername] = useState('');
  const [showDepositPopup, setShowDepositPopup] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const token = localStorage.getItem('authToken');
        console.log("Token pro /profile/credits:", token);

        // Logování požadavku
        console.log("Odesílám požadavek na /profile/credits s hlavičkami:", {
          Authorization: `Bearer ${token}`
        });

        const response = await axios.get('/profile/credits', {
          headers: { Authorization: `Bearer ${token}` },
        });

        // Logování odpovědi
        console.log("Odpověď z /profile/credits:", response);

        if (response.status === 200) {
          const { username, credits } = response.data;
          console.log("Přijatá data:", { username, credits });
          setUsername(username);
          setCredits(credits);
        }
      } catch (error) {
        // Logování chyby
        console.error('Chyba při načítání profilu:', error.response?.data || error.message);
      }
    };

    fetchProfile();
  }, [setCredits(credits)]);

  const handleDeposit = async (amount) => {
    try {
      const token = localStorage.getItem('authToken');
      console.log("Token pro /deposit:", token);

      // Logování požadavku
      console.log("Odesílám požadavek na /deposit s hlavičkami:", {
        Authorization: `Bearer ${token}`
      });

      const response = await axios.post(
        '/profile/deposit',
        { amount },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      // Logování odpovědi
      console.log("Odpověď z /deposit:", response);

      if (response.status === 200) {
        const { updatedCredits } = response.data;
        console.log("Přijatá data:", { updatedCredits });
        setCredits(updatedCredits);
        console.log(`Vklad úspěšný! Nový stav: ${updatedCredits}`);
        setShowDepositPopup(false);
      }
    } catch (error) {
      console.error('Chyba při vkladu:', error.response?.data || error.message);
    }
  };

  const handleClosePopup = (e) => {
    if (e.target.classList.contains('popup-overlay')) {
      setShowDepositPopup(false);
    }
  };

  useEffect(() => {
    const letters = document.querySelectorAll('.animated-logo .letter');
    const totalSpinTime = 300;
    const revealDelay = 50;
    const randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

    letters.forEach((letter, index) => {
      const interval = setInterval(() => {
        letter.textContent = randomChars[Math.floor(Math.random() * randomChars.length)];
      }, 100);

      setTimeout(() => {
        clearInterval(interval);
        letter.style.animation = 'none';
        letter.textContent = letter.getAttribute('data-final');
      }, totalSpinTime + index * revealDelay);
    });
  }, []);

  return (
    <nav className="navbar">
      <div className="navbar-left">
        <Link to="/" className="animated-logo">
          <span className="letter" data-final="P">X</span>
          <span className="letter" data-final="V">Q</span>
          <span className="letter" data-final="P">L</span>
          <span className="colon">:</span>
          <span className="letter" data-final="R">A</span>
          <span className="letter" data-final="N">T</span>
          <span className="letter" data-final="G">K</span>
        </Link>
      </div>
      <div className="navbar-center">
        <div className="navbar-item">
          <span className="navbar-label">Hráč:</span> <span>{username}</span>
        </div>
        <div className="navbar-item">
          <span className="navbar-label">Kredity:</span> <span>{credits}</span>
        </div>
      </div>
      <div className="navbar-right">
        <button
          className="navbar-button"
          onClick={() => setShowDepositPopup(true)}
        >
          Vložit
        </button>
        <Link
          to="/profile"
          className="navbar-button"
          style={{
            textDecoration: 'none',
            color: 'white',
            padding: '10px 15px',
            backgroundColor: '#1b2838',
            borderRadius: '5px',
            display: 'inline-block',
          }}
        >
          Profil
        </Link>
        <button
          className="navbar-button"
          onClick={handleLogout}
          style={{
            textDecoration: 'none',
            color: 'white',
            padding: '10px 15px',
            backgroundColor: '#1b2838',
            borderRadius: '5px',
            display: 'inline-block',
          }}
        >
          Odhlásit se
        </button>
      </div>

      {showDepositPopup && (
        <div className="popup-overlay" onClick={handleClosePopup}>
          <div className="popup-content">
            <h2>Vložit kredity</h2>
            <PaymentForm handleDeposit={handleDeposit} />
            <button
              className="close-button"
              onClick={() => setShowDepositPopup(false)}
            >
              Zavřít
            </button>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;