import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import { CreditsProvider } from './context/CreditsContext';
import LoginPage from './LoginPage';
import RegisterPage from './RegisterPage';
import GameMenuPage from './GameMenuPage';
import PlinkoGame from './components/PlinkoGame';
import Roulette from './components/Roulette';
import CrashGame from './components/CrashGame';
import SkinSearch from './components/SkinSearch';
import ProfilePage from './pages/ProfilePage';
import axios from 'axios';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState('');
  const [credits, setCredits] = useState(100); // Příklad: Počáteční kredity

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    console.log("Token nalezen v localStorage:", token);
    if (token) {
      setIsAuthenticated(true);
      // Načtení jména uživatele
      const fetchUsername = async () => {
        try {
          const response = await axios.get('/profile', {
            headers: { Authorization: `Bearer ${token}` },
          });
          console.log("Odpověď z /profile:", response.data);
          setUsername(response.data.username);
        } catch (error) {
          console.error('Chyba při načítání jména uživatele:', error.response?.data || error.message);
        }
      };
      fetchUsername();
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    setIsAuthenticated(false);
  };

  const PrivateRoute = ({ element }) => {
    return isAuthenticated ? element : <Navigate to="/" />;
  };

  return (
    <CreditsProvider>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/game" element={<PrivateRoute element={<GameMenuPage setIsAuthenticated={setIsAuthenticated} />} />} />
            <Route path="/game/plinko" element={<PrivateRoute element={<PlinkoGame />} />} />
            <Route path="/game/roulette" element={<PrivateRoute element={<Roulette credits={credits} setCredits={setCredits} username={username} handleLogout={handleLogout} />} />} />
            <Route path="/game/crash" element={<PrivateRoute element={<CrashGame credits={credits} setCredits={setCredits} username={username} handleLogout={handleLogout} />} />} />
            <Route path="/" element={<LoginPage setIsAuthenticated={setIsAuthenticated} />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/skinsearch" element={<PrivateRoute element={<SkinSearch />} />} />
            <Route path="/profile" element={<PrivateRoute element={<ProfilePage username={username} />} />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </div>
      </Router>
    </CreditsProvider>
  );
}

export default App;