import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './ProfilePage.css';
import Navbar from '../components/Navbar'; // Import Navbar
import bigInt from 'big-integer';

function Profile() {
    const [tradeLink, setTradeLink] = useState('');
    const [inventory, setInventory] = useState([]);
    const [isTradeLinkSaved, setIsTradeLinkSaved] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [error, setError] = useState('');
    const [selectedItem, setSelectedItem] = useState(null);
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [isLoadingPrices, setIsLoadingPrices] = useState(false);
    const navigate = useNavigate();
    const [selectedSkin, setSelectedSkin] = useState(null); // Pro uložení vybraného skinu
    const [skins, setSkins] = useState([]); // Pro uložení seznamu skinů
    const [steamID, setSteamID] = useState(''); // Pro uložení SteamID uživatele

    const getAuthToken = () => {
        const token = localStorage.getItem('authToken');
        console.log('[DEBUG] Token načten z localStorage:', token);
        return token;
    };

    const fetchInventoryPage = async (pageNumber) => {
        const authToken = getAuthToken();
    
        if (!authToken) {
            setError('Uživatel není přihlášen.');
            return;
        }
    
        try {
            const limit = 20; // Počet položek na stránku
            const offset = pageNumber * limit;
    
            console.log(`[DEBUG] Načítám inventář pro stránku ${pageNumber} s offsetem ${offset}`);
            const response = await axios.get(`/profile/inventory?limit=${limit}&offset=${offset}`, {
                headers: { Authorization: `Bearer ${authToken}` },
                withCredentials: true,
            });
    
            const { inventory: newItems, total } = response.data;
    
            // Přidání market_hash_name do skinů
            const updatedItems = newItems.map((item) => ({
                ...item,
                market_hash_name: item.name, // Použijeme `name` jako `market_hash_name`
            }));
    
            console.log('[DEBUG] Upravené skiny s market_hash_name:', updatedItems);
    
            const inventoryWithPrices = await fetchSkinPrices(updatedItems); // Přidání cen
            setInventory((prevItems) => [...prevItems, ...inventoryWithPrices]);
            setHasMore(offset + newItems.length < total); // Zjistíme, zda máme více dat
        } catch (err) {
            setError('Nepodařilo se načíst další stránku inventáře.');
            console.error('[DEBUG] Chyba při načítání inventáře:', err);
        }
    };
    
    const handleTrade = async () => {
        console.log('[DEBUG] handleTrade called');
        console.log('[DEBUG] selectedSkin:', selectedSkin);
        console.log('[DEBUG] steamID:', steamID);
        if (!selectedSkin || !steamID) {
            console.log('[DEBUG] selectedSkin or steamID is missing', { selectedSkin, steamID });
            return;
        }
      
        try {
          console.log('[DEBUG] Odesílám obchodní nabídku:', selectedSkin);
          const response = await axios.post('/trade/trade', {
            steamID: steamID, // Použijeme skutečné SteamID uživatele
            skinID: selectedSkin.assetid,
            credits: selectedSkin.price, // Předpokládáme, že cena skinu je v kreditech
          });
      
          if (response.status === 200) {
            alert('Trade offer sent and credits updated');
          } else {
            console.error('[DEBUG] Neúspěšná odpověď:', response);
          }
        } catch (error) {
          console.error('Chyba při odesílání obchodní nabídky:', error);
        }
      };

    const fetchSkinPrices = async (skins) => {
        const token = getAuthToken();

        if (!token) {
            console.error('[DEBUG] Token chybí. Nelze načíst ceny skinů.');
            throw new Error('Chybějící token');
        }

        try {
            console.log('[DEBUG] Seznam skinů odesílaný na server:', skins);

            setIsLoadingPrices(true);
            const response = await axios.post(
                '/api/skin-prices',
                { skins },
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            console.log('[DEBUG] Odpověď ze serveru na skin-prices:', response.data);

            setIsLoadingPrices(false);

            return skins.map((skin) => {
                console.log(`[DEBUG] Mapuji skin: ${skin.market_hash_name}`);
                const priceData = response.data.find((item) => item.marketHashName === skin.market_hash_name);

                if (!priceData) {
                    console.warn(`[DEBUG] Nenalezena cena pro skin: ${skin.market_hash_name}`);
                }

                return { ...skin, price: priceData?.price || 'Cena nedostupná' };
            });
        } catch (error) {
            console.error('[DEBUG] Chyba při načítání cen:', error.message);
            setIsLoadingPrices(false);
            return skins.map((skin) => ({ ...skin, price: 'Chyba při načítání' }));
        }
    };

    const fetchProfileData = async () => {
        const authToken = getAuthToken();

        if (!authToken) {
            console.error('[DEBUG] Uživatel není přihlášen. Žádný token není uložen.');
            setError('Uživatel není přihlášen.');
            return;
        }

        try {
            console.log('[DEBUG] Načítám trade link...');
            const tradeLinkResponse = await axios.get('/profile/trade-link', {
                headers: { Authorization: `Bearer ${authToken}` },
                withCredentials: true,
            });

            console.log('[DEBUG] Odpověď ze serveru na trade-link:', tradeLinkResponse.data);

            const savedTradeLink = tradeLinkResponse.data.tradeLink;
            let userSteamID;
            if (savedTradeLink) {
                console.log('[DEBUG] Trade link existuje:', savedTradeLink);
                setTradeLink(savedTradeLink);

                // Extrakce steamID z tradeLink
                const urlParams = new URLSearchParams(new URL(savedTradeLink).search);
                const steamID32 = urlParams.get('partner');
                console.log('[DEBUG] Extrahované SteamID32:', steamID32);

                // Převod SteamID32 na SteamID64
                userSteamID = bigInt(steamID32).add(bigInt('76561197960265728'));
                console.log('[DEBUG] Převedené SteamID64:', userSteamID);

                setSteamID(userSteamID.toString()); // Nastavíme SteamID uživatele
                console.log('[DEBUG] SteamID nastaveno:', userSteamID.toString());
                setIsTradeLinkSaved(true);
            } else {
                console.log('[DEBUG] Žádný trade link není uložen.');
                setIsTradeLinkSaved(false);
                return;
            }

            fetchInventoryPage(0);
        } catch (err) {
            setError('Nepodařilo se načíst profil.');
            console.error('[DEBUG] Chyba při načítání profilu:', err);
        }
    };

    const handleSaveTradeLink = async () => {
        const authToken = getAuthToken();

        if (!authToken) {
            console.error('[DEBUG] Uživatel není přihlášen. Žádný token není uložen.');
            setError('Uživatel není přihlášen.');
            return;
        }

        try {
            console.log('[DEBUG] Ukládám trade link:', tradeLink);
            const response = await axios.post(
                '/profile/trade-link',
                { tradeLink },
                {
                    headers: { Authorization: `Bearer ${authToken}` },
                    withCredentials: true,
                }
            );

            console.log('[DEBUG] Trade link byl úspěšně uložen:', response.data);
            setSuccessMessage('Trade link byl úspěšně uložen.');
            setIsTradeLinkSaved(true);
            fetchProfileData();
        } catch (err) {
            const errorMessage = err.response?.data?.message || 'Nepodařilo se uložit trade link.';
            console.error('[DEBUG] Chyba při ukládání trade linku:', errorMessage, err);
            setError(errorMessage);
        }
    };

    const handleItemClick = (assetid) => {
        const selectedItem = inventory.find(item => item.assetid === assetid);
        setSelectedItem(assetid);
        setSelectedSkin(selectedItem);
        console.log(`[DEBUG] Označený item: ${assetid}`, selectedItem);
    };

    const loadMoreItems = () => {
        if (hasMore) {
            setPage((prevPage) => prevPage + 1);
        }
    };

    const handleScroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) return;
        loadMoreItems();
    };

    const handleLogout = () => {
        localStorage.removeItem('authToken');
        navigate('/');
    };

    useEffect(() => {
        fetchProfileData();
    }, []);

    useEffect(() => {
        if (page > 0) {
            fetchInventoryPage(page);
        }
    }, [page]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [hasMore]);

    if (error) {
        console.error('[DEBUG] Chyba při načítání stránky:', error);
        return <p className="profile-error">{error}</p>;
    }

    if (!isTradeLinkSaved) {
        return (
            <div>
                <Navbar username="Hráč" handleLogout={handleLogout} />
                <div className="profile-container">
                    <h1 className="profile-header">Přidat Steam Trade Link</h1>
                    <div className="trade-link-section">
                        <h2>Zadejte váš Steam trade link</h2>
                        <label htmlFor="steamTradeLink">Steam Trade Link:</label>
                        <input
                            type="text"
                            id="steamTradeLink"
                            name="steamTradeLink"
                            value={tradeLink}
                            onChange={(e) => setTradeLink(e.target.value)}
                            placeholder="Vložte váš Steam trade link"
                            autoComplete="on"
                        />
                        <button onClick={handleSaveTradeLink}>Uložit</button>
                        {successMessage && <p className="success-message">{successMessage}</p>}
                        {error && <p className="error-message">{error}</p>}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div>
            <Navbar username="Hráč" handleLogout={handleLogout} />
            <div className="profile-container">
                <h1 className="profile-header">Vítejte!</h1>
                <div className="inventory-section">
                    <h2>Inventář</h2>
                    {isLoadingPrices && <p>Načítám ceny skinů...</p>}
                    <div className="inventory-grid">
                        {inventory.map((item) => (
                            <div
                                className={`inventory-item ${
                                    selectedItem === item.assetid ? 'selected' : ''
                                }`}
                                key={item.assetid}
                                onClick={() => handleItemClick(item.assetid)}
                            >
                                {item.icon_url ? (
                                    <img src={item.icon_url} alt={item.name} className="inventory-item-image" />
                                ) : (
                                    <div className="inventory-placeholder">Obrázek není dostupný</div>
                                )}
                                <p><strong>{item.name}</strong></p>
                                <p>{item.price}</p>
                            </div>
                        ))}
                    </div>
                    <div className="skins-list">
                        {skins.map((skin) => (
                            <div
                                key={skin.id}
                                className={`skin-item ${selectedSkin && selectedSkin.id === skin.id ? 'selected' : ''}`}
                                onClick={() => setSelectedSkin(skin)}
                            >
                                {skin.name}
                            </div>
                        ))}
                    </div>
                    {hasMore && (
                        <button className="load-more" onClick={() => setPage((prevPage) => prevPage + 1)}>
                            Načíst více
                        </button>
                    )}
                </div>
                <button className="trade-button" onClick={handleTrade} disabled={!selectedSkin}>
                    Obchodovat vybraný skin
                </button>
            </div>
        </div>
    );
}

export default Profile;