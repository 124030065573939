import React, { useState } from 'react';
import axios from 'axios';

function ValidateInventory() {
    const [steamUrl, setSteamUrl] = useState('');
    const [item, setItem] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const handleValidate = async () => {
        if (!steamUrl.trim()) {
            setError('Zadejte odkaz na Steam inventář.');
            return;
        }

        setLoading(true);
        setError('');
        setItem(null);

        try {
            // Volání backendu s předáním URL inventáře
            const response = await axios.post('/validate-inventory', { steamUrl }); // Upravte URL podle vašeho backendu
            setItem(response.data.item);
        } catch (err) {
            // Zpracování chyb
            setError(err.response?.data?.error || 'Chyba při validaci inventáře.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ maxWidth: '600px', margin: '0 auto', textAlign: 'center', padding: '20px' }}>
            <h1>Ověření Steam Inventáře</h1>
            <input
                type="text"
                placeholder="Vložte odkaz na Steam inventář"
                value={steamUrl}
                onChange={(e) => setSteamUrl(e.target.value)}
                style={{
                    width: '100%',
                    padding: '10px',
                    marginBottom: '10px',
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                }}
            />
            <button
                onClick={handleValidate}
                style={{
                    padding: '10px 20px',
                    backgroundColor: '#007bff',
                    color: 'white',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer',
                }}
                disabled={loading}
            >
                {loading ? 'Ověřuji...' : 'Ověřit Inventář'}
            </button>
            {error && <p style={{ color: 'red', marginTop: '10px' }}>{error}</p>}
            {item && (
                <div style={{ marginTop: '20px', textAlign: 'left' }}>
                    <h2>Výsledek</h2>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            border: '1px solid #ddd',
                            padding: '10px',
                            borderRadius: '5px',
                            backgroundColor: '#f9f9f9',
                        }}
                    >
                        <img
                            src={item.icon_url}
                            alt={item.name}
                            style={{ width: '50px', height: '50px', marginRight: '10px' }}
                        />
                        <div>
                            <p>
                                <strong>Název:</strong> {item.name}
                            </p>
                            <p>
                                <strong>Obchodovatelné:</strong> {item.tradable ? 'Ano' : 'Ne'}
                            </p>
                            <p>
                                <strong>Opotřebení:</strong> {item.wear}
                            </p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ValidateInventory;
