import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Navbar from './components/Navbar'; // Import Navbar
import './GameMenuPage.css';

function GameMenuPage({ setIsAuthenticated }) {
  const [username, setUsername] = useState('');
  const [userId, setUserId] = useState(null); // Pro uložení ID uživatele
  const [skins, setSkins] = useState([]);
  const [offset, setOffset] = useState(0);
  const [credits, setCredits] = useState(0); // Přidání stavu pro kredity
  const isDragging = useRef(false);
  const startX = useRef(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPlayerData = async () => {
      try {
        const token = localStorage.getItem('authToken');
        const response = await axios.get('/player', {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (response.status === 200) {
          setUsername(response.data.username);
          setUserId(response.data.id);
          setCredits(response.data.credits); // Nastavení kreditů
        }
      } catch (error) {
        console.error('Chyba při načítání dat hráče:', error);
        setIsAuthenticated(false);
      }
    };

    fetchPlayerData();
  }, [setIsAuthenticated]);

  useEffect(() => {
    const initialSkins = Array.from({ length: 20 }, (_, i) => ({
      id: Date.now() + i,
      name: `Skin ${Math.floor(Math.random() * 100) + 1}`,
    }));
    setSkins(initialSkins);
  }, []);

  const handleMouseDown = (e) => {
    isDragging.current = true;
    startX.current = e.clientX;
  };

  const handleMouseMove = (e) => {
    if (!isDragging.current) return;
    const dx = e.clientX - startX.current;
    setOffset((prevOffset) => prevOffset + dx);
    startX.current = e.clientX;
  };

  const handleMouseUp = () => {
    isDragging.current = false;
  };

  const goToPlinko = () => {
    navigate('/game/plinko');
  };

  const goToRoulette = () => {
    navigate('/game/roulette');
  };

  const goToCrash = () => {
    navigate('/game/crash');
  };

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    setIsAuthenticated(false);
    navigate('/');
  };

  const getTimeRemaining = (endTime) => {
    const total = Date.parse(endTime) - Date.parse(new Date());
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const seconds = Math.floor((total / 1000) % 60);
    return { total, hours, minutes, seconds };
  };

  const getPercentageElapsed = (startTime, endTime) => {
    const total = Date.parse(endTime) - Date.parse(startTime);
    const elapsed = Date.parse(new Date()) - Date.parse(startTime);
    return (elapsed / total) * 100;
  };

  const getDailyEndTime = () => {
    const now = new Date();
    const endTime = new Date(now);
    endTime.setHours(22, 0, 0, 0);
    if (now > endTime) {
      endTime.setDate(endTime.getDate() + 1);
    }
    return endTime;
  };

  const getWeeklyEndTime = () => {
    const now = new Date();
    const endTime = new Date(now);
    endTime.setDate(now.getDate() + (7 - now.getDay()) % 7);
    endTime.setHours(22, 0, 0, 0);
    return endTime;
  };

  const getMonthlyEndTime = () => {
    const now = new Date();
    const endTime = new Date(now.getFullYear(), now.getMonth() + 1, 0, 22, 0, 0, 0);
    return endTime;
  };

  const dailyEndTime = getDailyEndTime();
  const weeklyEndTime = getWeeklyEndTime();
  const monthlyEndTime = getMonthlyEndTime();

  const dailyTimeRemaining = getTimeRemaining(dailyEndTime);
  const weeklyTimeRemaining = getTimeRemaining(weeklyEndTime);
  const monthlyTimeRemaining = getTimeRemaining(monthlyEndTime);

  const dailyPercentageElapsed = getPercentageElapsed(new Date(), dailyEndTime);
  const weeklyPercentageElapsed = getPercentageElapsed(new Date(), weeklyEndTime);
  const monthlyPercentageElapsed = getPercentageElapsed(new Date(), monthlyEndTime);

  return (
    <div>
      <Navbar username={username} credits={credits} handleLogout={handleLogout} />
      <div
        className="top-bar"
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
      >
        {skins.map((skin, index) => (
          <div
            key={skin.id}
            className="skin-box"
            style={{
              transform: `translateX(${offset}px)`,
              left: `${index * 150}px`,
            }}
          >
            {skin.name}
          </div>
        ))}
      </div>
      <div className="container">
        <div className="side-menu">
          <button className="side-menu-button" style={{ background: `linear-gradient(to right, #3498db ${dailyPercentageElapsed}%, #2980b9 ${dailyPercentageElapsed}%)` }}>
            Daily - {dailyTimeRemaining.hours}h {dailyTimeRemaining.minutes}m
          </button>
          <button className="side-menu-button" style={{ background: `linear-gradient(to right, #3498db ${weeklyPercentageElapsed}%, #2980b9 ${weeklyPercentageElapsed}%)` }}>
            Weekly - {weeklyTimeRemaining.hours}h {weeklyTimeRemaining.minutes}m
          </button>
          <button className="side-menu-button" style={{ background: `linear-gradient(to right, #3498db ${monthlyPercentageElapsed}%, #2980b9 ${monthlyPercentageElapsed}%)` }}>
            Monthly - {monthlyTimeRemaining.hours}h {monthlyTimeRemaining.minutes}m
          </button>
        </div>

        <div className="section">
          <h2>PVP</h2>
          <div className="pvp-section">
            <button className="game-box" onClick={goToRoulette}>Ruleta</button>
            <button className="game-box" onClick={goToCrash}>Crash</button>
            <button className="game-box">Hra 3</button>
          </div>
        </div>

        <div className="section">
          <h2>RNG</h2>
          <div className="rng-section">
            <button className="game-box" onClick={goToPlinko}>
              Plinko
            </button>
            <button className="game-box">Crash</button>
            <button className="game-box">Sloty</button>
          </div>
        </div>

        <div className="friend-list">
          <button className="friend-list-button">Přítel 1</button>
          <button className="friend-list-button">Přítel 2</button>
          <button className="friend-list-button">Přítel 3</button>
        </div>
      </div>
    </div>
  );
}

export default GameMenuPage;