import React, { useEffect, useRef, useState, useContext } from 'react';
import Matter from 'matter-js';
import Navbar from './Navbar'; // Import nové komponenty Navbar
import { useNavigate } from 'react-router-dom'; // Přidání useNavigate
import { CreditsContext } from '../context/CreditsContext'; // Import CreditsContext

function PlinkoGame() {
  const sceneRef = useRef(null);
  const engineRef = useRef(Matter.Engine.create());
  const ballCounterRef = useRef(0);
  const pegSpacing = 50;
  const ballSize = 15;
  const pegs = useRef([]); // Ref pro uložení všech kolíků
  const finalTargets = useRef([]); // Ref pro uložení finálních čtverečků
  const targetPegsMap = useRef(new Map()); // Mapa pro uložení cesty pro každý míček


  const [username, setUsername] = useState('');
  //const [credits, setCredits] = useState(0);
  const { credits, setCredits } = useContext(CreditsContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    navigate('/');
  };

  useEffect(() => {
    const engine = engineRef.current;
    const render = Matter.Render.create({
      element: sceneRef.current,
      engine: engine,
      options: {
        width: 800,
        height: 800,
        wireframes: false,
      },
    });

    const numRows = 13; // Počet řádků kolíků
    const startX = 400;
    const startY = 100;
    let pegCounter = 1;

    // Vytvoření 13 řádků kolíků
    for (let i = 0; i < numRows; i++) {
      const numPegsInRow = 3 + i;
      for (let j = 0; j < numPegsInRow; j++) {
        const x = startX + j * pegSpacing - ((numPegsInRow - 1) * pegSpacing) / 2;
        const y = startY + i * pegSpacing;

        if (j !== 0 && j !== numPegsInRow - 1) {
          const peg = Matter.Bodies.circle(x, y, 5, {
            isStatic: true,
            label: `Peg ${pegCounter}`,
            render: {
              fillStyle: '#FFFFFF',
              id: pegCounter,
            },
          });
          pegs.current.push({ x, y, id: pegCounter, body: peg });
          Matter.World.add(engine.world, peg);
          pegCounter++;
        } else {
          const peg = Matter.Bodies.circle(x, y, 5, { isStatic: true });
          Matter.World.add(engine.world, peg);
        }
      }
    }

    // Vytvoření finálních čtverečků na poslední (13.) řadě kolíků
    const numTargets = numRows;
    const lastRowY = startY + (numRows - 1) * pegSpacing; // Y-pozice pro 13. řadu
    for (let i = 0; i < numTargets; i++) {
      const x = startX + i * pegSpacing - ((numTargets - 1) * pegSpacing) / 2;
      const target = Matter.Bodies.rectangle(x, lastRowY, 20, 20, {
        isStatic: true,
        label: `Target ${i + 1}`,
        render: {
          fillStyle: '#00FF00',
        },
      });
      finalTargets.current.push({ x, y: lastRowY, id: i + 1, body: target });
      Matter.World.add(engine.world, target);
    }

    Matter.Render.run(render);
    const runner = Matter.Runner.create();
    Matter.Runner.run(runner, engine);

    return () => {
      Matter.Render.stop(render);
      Matter.World.clear(engine.world);
      Matter.Engine.clear(engine);
    };
  }, []);

  const findNextPeg = (currentPegId, direction, levels) => {
    for (let i = 0; i < levels.length; i++) {
      const level = levels[i];
      const index = level.indexOf(currentPegId);
      if (index !== -1) {
        if (direction === 'left') {
          return levels[i + 1]?.[index]; // Levý spodní kolík
        } else if (direction === 'right') {
          return levels[i + 1]?.[index + 1]; // Pravý spodní kolík
        }
      }
    }
    return null;
  };

  const calculateDirectionToPeg = (ballPosition, targetPeg) => {
    const dx = targetPeg.x - ballPosition.x;
    const dy = targetPeg.y - ballPosition.y;
    const magnitude = Math.sqrt(dx * dx + dy * dy);
    //console.log(`Vypočtený směr: dx=${dx}, dy=${dy}, magnitude=${magnitude}`);
    return { x: (dx / magnitude) * 10, y: (dy / magnitude) * 10 };
  };

  const resetPegsColor = () => {
    pegs.current.forEach((peg) => {
      if (peg.body.render) {
        peg.body.render.fillStyle = '#FFFFFF';
      }
    });
  };

  const highlightPath = (path) => {
    path.forEach((pegId) => {
      const peg = pegs.current.find(p => p.id === pegId);
      //if (peg && peg.body.render) {
        //peg.body.render.fillStyle = '#FF0000'; // Zvýraznění cesty
      //}
    });
  };

  const handleStartGame = () => {
    resetPegsColor();

    fetch('/gameResults/plinko-path?clientSeed=myCustomSeed')
      .then(response => response.json())
      .then(data => {
        const generatedPath = data.path;
        //console.log('Vygenerovaná cesta:', generatedPath);

        let currentPegId = 1;
        const highlightPegs = [currentPegId];

        const levels = pegs.current.reduce((acc, peg) => {
          const yLevel = peg.y;
          if (!acc[yLevel]) acc[yLevel] = [];
          acc[yLevel].push(peg.id);
          return acc;
        }, {});

        Object.values(levels).forEach(level => level.sort((a, b) => a - b));

        generatedPath.forEach((direction) => {
          currentPegId = findNextPeg(currentPegId, direction, Object.values(levels));
          if (currentPegId !== null && pegs.current.some(peg => peg.id === currentPegId)) {
            highlightPegs.push(currentPegId);
          }
        });

        highlightPath(highlightPegs); // Zvýraznění cesty míčku

        const createNewBall = () => {
          const ballId = `Ball-${ballCounterRef.current++}`; // Unikátní ID pro míček
          const firstPeg = pegs.current.find(peg => peg.id === 1);
          if (firstPeg) {
            const ball = Matter.Bodies.circle(firstPeg.x, firstPeg.y - pegSpacing, ballSize, {
              restitution: 0.5,
              friction: 0.1,
              frictionAir: 0.02,
              label: ballId, // Přidání ID do labelu míčku
            });
            targetPegsMap.current.set(ballId, [...highlightPegs]); // Uložení cesty pro míček
            Matter.World.add(engineRef.current.world, ball);
            //console.log(`Vytvořen nový míček: ${ballId}, cesta: `, highlightPegs);
            return ball;
          }
        };

        let ball = createNewBall();

        Matter.Events.on(engineRef.current, 'beforeUpdate', () => {
          if (ball && !ball.isAtTarget && !ball.isRemoved) {
            moveToNextPeg(ball);
          }
        });

        Matter.Events.on(engineRef.current, 'collisionStart', (event) => {
          event.pairs.forEach((pair) => {
            const ball = pair.bodyA.label.includes('Ball') ? pair.bodyA : pair.bodyB;
            const ballPath = targetPegsMap.current.get(ball.label);

            if (ball && ballPath) {
              const collidedPeg = pegs.current.find(peg => peg.body === (pair.bodyA === ball ? pair.bodyB : pair.bodyA));
              if (collidedPeg) {
                //console.log(`Míček ${ball.label} narazil do kolíku: ${collidedPeg.id}`);
                if (ballPath[0] === collidedPeg.id) {
                  //console.log(`Míček ${ball.label} trefil správný kolík: ${collidedPeg.id}`);
                  ballPath.shift(); // Smazání prvního kolíku z cesty
                } else {
                  //console.log(`Míček ${ball.label} narazil do nesprávného kolíku: ${collidedPeg.id}`);
                }
              }

              // Kontrola pro dopad do finálních čtverečků
              const collidedTarget = finalTargets.current.find(target => target.body === (pair.bodyA === ball ? pair.bodyB : pair.bodyA));
              if (collidedTarget) {
                //console.log(`Míček ${ball.label} dopadl do cílové zóny: ${collidedTarget.id}`);
                Matter.World.remove(engineRef.current.world, ball);
                ball.isRemoved = true; // Nastavení flagu pro zastavení dalších akcí
              }
            }
          });
        });
      })
      .catch(error => console.error('Chyba při volání API:', error));
  };

  const moveToNextPeg = (ball) => {
    const ballPath = targetPegsMap.current.get(ball.label);
    if (ballPath && ballPath.length > 0) {
      const nextPegId = ballPath[0];
      const nextPeg = pegs.current.find(peg => peg.id === nextPegId);

      if (nextPeg) {
        //console.log(`Míček ${ball.label} směřuje ke kolíku: ${nextPeg.id}`);
        const direction = calculateDirectionToPeg(ball.position, nextPeg);
        //console.log(`Nastavení rychlosti míčku ${ball.label}: x=${direction.x}, y=${direction.y}`);
        Matter.Body.setVelocity(ball, {
          x: direction.x,
          y: direction.y,
        });
      }
    } else {
      //console.log(`Míček ${ball.label} nemá další cíl.`);
    }
  };

  return (
    <div>
      <Navbar username={username} credits={credits} handleLogout={handleLogout} />
      <div ref={sceneRef} />
      <button onClick={handleStartGame}>Spustit míček</button>
    </div>
  );
}

export default PlinkoGame;
//Final verze
