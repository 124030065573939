import React, { useState } from 'react';
import axios from 'axios';
import './PaymentForm.css';

const PaymentForm = () => {
  const [amount, setAmount] = useState('');
  const [currency, setCurrency] = useState('EUR'); // Defaultní měna
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');

    console.log('Odesílám žádost:', { amount, currency });

    try {
        const response = await axios.post('/create-checkout-session', {
            amount,
            currency,
        });

        if (response.data.url) {
            window.location.href = response.data.url;
        } else {
            setMessage('Chyba při vytváření platby. Zkuste to znovu.');
        }
    } catch (error) {
        console.error('Chyba při zpracování platby:', error.response?.data || error.message);
        setMessage('Chyba při zpracování platby.');
    }
};


  return (
    <div>
      <h2>Provést platbu</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="amount">Částka:</label>
          <input
            type="number"
            id="amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            required
            min="1"
          />
        </div>
        <div>
          <label htmlFor="currency">Měna:</label>
          <select
            id="currency"
            value={currency}
            onChange={(e) => setCurrency(e.target.value)}
          >
            <option value="EUR">Euro</option>
            <option value="USD">Dolar</option>
          </select>
        </div>
        <button type="submit">Pokračovat na platbu</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default PaymentForm;
