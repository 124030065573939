import React, { useState } from 'react';
import './Roulette.css';
import Navbar from './Navbar';

const Roulette = ({ credits, setCredits, username, handleLogout }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [result, setResult] = useState(null);
  const [color, setColor] = useState(null);

  const handleStartGame = () => {
    setIsPlaying(true);
    setResult(null);
    setColor(null);

    fetch('/gameResults/roulette-result?clientSeed=myCustomSeed')
      .then(response => response.json())
      .then(data => {
        setResult(data.result);
        setColor(data.color);
        setIsPlaying(false);
        startRouletteAnimation(data.result);
      })
      .catch(error => {
        console.error('Chyba při volání API:', error);
        setIsPlaying(false);
      });
  };

  const startRouletteAnimation = (winningNumber) => {
    // Animace bude přidána později
  };

  return (
    <div>
      <Navbar username={username} credits={credits} handleLogout={handleLogout} />
      <div className="roulette-container">
        <div className="result-display">
          <div>Výsledek: {result !== null ? result : '?'}</div>
          <div>Barva: {color !== null ? color : '?'}</div>
        </div>
        <button onClick={handleStartGame} disabled={isPlaying} className="start-button">
          {isPlaying ? 'Točí se...' : 'Roztočit ruletu'}
        </button>
        <div className="betting-controls">
          <div className="betting-column">
            <button className="bet-button zero">0</button>
          </div>
          <div className="betting-column">
            <button className="bet-button one">1</button>
            <button className="bet-button two">2</button>
            <button className="bet-button three">3</button>
          </div>
          <div className="betting-column">
            <button className="bet-button four">4</button>
            <button className="bet-button five">5</button>
            <button className="bet-button six">6</button>
          </div>
          <div className="betting-column">
            <button className="bet-button seven">7</button>
            <button className="bet-button eight">8</button>
            <button className="bet-button nine">9</button>
          </div>
          <div className="betting-column">
            <button className="bet-button ten">10</button>
            <button className="bet-button eleven">11</button>
            <button className="bet-button twelve">12</button>
          </div>
          <div className="betting-column">
            <button className="bet-button thirteen">13</button>
            <button className="bet-button fourteen">14</button>
            <button className="bet-button fifteen">15</button>
          </div>
          <div className="betting-column">
            <button className="bet-button sixteen">16</button>
            <button className="bet-button seventeen">17</button>
            <button className="bet-button eighteen">18</button>
          </div>
          <div className="betting-column">
            <button className="bet-button nineteen">19</button>
            <button className="bet-button twenty">20</button>
            <button className="bet-button twenty-one">21</button>
          </div>
          <div className="betting-column">
            <button className="bet-button twenty-two">22</button>
            <button className="bet-button twenty-three">23</button>
            <button className="bet-button twenty-four">24</button>
          </div>
          <div className="betting-column">
            <button className="bet-button twenty-five">25</button>
            <button className="bet-button twenty-six">26</button>
            <button className="bet-button twenty-seven">27</button>
          </div>
          <div className="betting-column">
            <button className="bet-button twenty-eight">28</button>
            <button className="bet-button twenty-nine">29</button>
            <button className="bet-button thirty">30</button>
          </div>
          <div className="betting-column">
            <button className="bet-button thirty-one">31</button>
            <button className="bet-button thirty-two">32</button>
            <button className="bet-button thirty-three">33</button>
          </div>
          <div className="betting-column">
            <button className="bet-button thirty-four">34</button>
            <button className="bet-button thirty-five">35</button>
            <button className="bet-button thirty-six">36</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Roulette;