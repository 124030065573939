import React, { useState, useEffect, useRef } from 'react';
import './CrashGame.css';
import Navbar from './Navbar';

function CrashGame({ credits, setCredits, username, handleLogout }) {
  const [multiplier, setMultiplier] = useState(1);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isCrashed, setIsCrashed] = useState(false);
  const [crashPoint, setCrashPoint] = useState(null);
  const [serverSeed, setServerSeed] = useState('');
  const [clientSeed, setClientSeed] = useState('myCustomSeed');
  const lineRef = useRef(null);

  const handleStartGame = () => {
    setIsPlaying(true);
    setIsCrashed(false);
    setMultiplier(1);

    fetch(`/gameResults/crash-point?clientSeed=${clientSeed}`)
      .then(response => response.json())
      .then(data => {
        setCrashPoint(data.crashPoint);
        setServerSeed(data.serverSeed);
        startCrashAnimation(data.crashPoint);
      })
      .catch(error => {
        console.error('Chyba při volání API:', error);
        setIsPlaying(false);
      });
  };

  const startCrashAnimation = (crashPoint) => {
    const interval = setInterval(() => {
      setMultiplier(prevMultiplier => {
        const newMultiplier = prevMultiplier + 0.01;
        if (newMultiplier >= crashPoint) {
          clearInterval(interval);
          setIsCrashed(true);
          setIsPlaying(false);
        }
        return newMultiplier;
      });
    }, 100);
  };

  useEffect(() => {
    if (lineRef.current) {
      lineRef.current.style.transform = `scaleY(${multiplier})`;
    }
  }, [multiplier]);

  return (
    <div>
      <Navbar username={username} credits={credits} handleLogout={handleLogout} />
      <div className="crash-game-container">
        <h1>Crash Game</h1>
        <div className="multiplier-display">
          {isCrashed ? `Crashed at ${multiplier.toFixed(2)}x` : `${multiplier.toFixed(2)}x`}
        </div>
        <div className="line-container">
          <div className="line" ref={lineRef}></div>
        </div>
        <div className="controls">
          <button onClick={handleStartGame} disabled={isPlaying}>
            Start Game
          </button>
        </div>
        {crashPoint && (
          <div className="crash-info">
            <p>Crash Point: {crashPoint}</p>
            <p>Server Seed: {serverSeed}</p>
            <p>Client Seed: {clientSeed}</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default CrashGame;